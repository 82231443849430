.change{
    background-color: rgb(246, 241, 241);
    border-radius: 10px;
    color: aqua;
    
  }

  .effect:hover{
    background-color: rgb(187, 189, 191);
    font-style: italic;
    border-radius: 8px;
  }

  .real{
    height: 33px;
  }